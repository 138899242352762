import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { sendMessageToOpenAI } from '../utils/api';
import TypeWriter from './TypeWriter';
import TypingIndicator from './TypingIndicator';
import ReactMarkdown from 'react-markdown';

const WidgetContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: ${props => props.position}px;
  z-index: 1000;
  user-select: none;
  transition: right 0.1s ease;
`;

const ChatButton = styled.button`
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  background: linear-gradient(
    to right, 
    hsl(45, 100%, 72%), 
    hsl(35, 100%, 68%)
  );
  color: hsl(240, 2%, 12%);
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  font-weight: 500;
  display: flex;
  
  &:hover {
    opacity: 0.9;
  }
`;

const ChatWindow = styled.div.attrs(props => ({
  'data-fullscreen': props.isFullScreen
}))`
  width: ${props => props['data-fullscreen'] ? '100vw' : '300px'};
  height: ${props => props['data-fullscreen'] ? '100vh' : '400px'};
  max-width:  950px;
  background: hsl(240, 2%, 12%);
  border-radius: ${props => props['data-fullscreen'] ? '0' : '10px'};
  box-shadow: 0 2px 10px rgba(0,0,0,0.3);
  display: flex;
  flex-direction: column;
  position: ${props => props['data-fullscreen'] ? 'fixed' : 'relative'};
  top: ${props => props['data-fullscreen'] ? '0' : 'auto'};
  right: ${props => props['data-fullscreen'] ? '0' : 'auto'};
  bottom: ${props => props['data-fullscreen'] ? '0' : 'auto'};
  transition: all 0.3s ease;
  z-index: 1000;
  border: 1px solid hsl(240, 2%, 18%);
`;

const Header = styled.div`
  padding: 10px;
  border-bottom: 1px solid hsl(40, 100%, 70%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    to right,
    hsla(45, 100%, 72%, 0.1),
    hsla(35, 100%, 68%, 0.1)
  );
`;

const HeaderTitle = styled.div`
  font-weight: 500;
  color: hsl(45, 100%, 72%);
  background: linear-gradient(
    to right, 
    hsl(45, 100%, 72%), 
    hsl(35, 100%, 68%)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const HeaderControls = styled.div`
  display: flex;
  gap: 10px;
`;

const ControlButton = styled.button`
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: hsl(45, 100%, 72%);
  padding: 0 5px;
  &:hover {
    color: hsl(35, 100%, 68%);
  }
`;

const MessagesContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  scroll-behavior: smooth;
  background: hsl(240, 2%, 13%);
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-track {
    background: hsl(240, 2%, 15%);
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: hsl(45, 100%, 72%);
    border-radius: 3px;
  }
`;

const MessageBubble = styled.div.attrs(props => ({
  'data-user': props.isUser
}))`
  padding: 8px 12px;
  border-radius: 15px;
  max-width: 80%;
  word-wrap: break-word;
  ${props => props['data-user'] ? `
    background: linear-gradient(
      to right, 
      hsl(45, 100%, 72%), 
      hsl(35, 100%, 68%)
    );
    color: hsl(240, 2%, 12%);
    align-self: flex-end;
  ` : `
    background: hsl(0, 0%, 22%);
    color: hsl(0, 0%, 98%);
    align-self: flex-start;
  `}

  .markdown-content {
    text-align: left;
    line-height: 1.6;

    p {
      margin: 0;
    }
    
    a {
      color: hsl(45, 100%, 72%);
      text-decoration: none;
      
      &:hover {
        text-decoration: underline;
      }
    }
    
    code {
      background: ${props => props['data-user'] ? 
        'rgba(0, 0, 0, 0.1)' : 
        'hsla(45, 100%, 72%, 0.1)'
      };
      padding: 0.2em 0.4em;
      border-radius: 3px;
      font-size: 0.9em;
    }
  }
`;

const InputContainer = styled.div`
  padding: 10px;
  border-top: 1px solid hsl(40, 100%, 70%);
  display: flex;
  gap: 10px;
  background: linear-gradient(
    to right,
    hsla(45, 100%, 72%, 0.1),
    hsla(35, 100%, 68%, 0.1)
  );
`;

const Input = styled.textarea.attrs(props => ({
  ref: props.ref
}))`
  flex-grow: 1;
  padding: 8px 12px;
  border: 1px solid hsl(0, 0%, 22%);
  border-radius: 20px;
  outline: none;
  background: hsl(240, 2%, 12%);
  color: hsl(0, 0%, 98%);
  resize: none;
  font-family: inherit;
  line-height: 1.2;
  
  &:focus {
  }
  
  &::placeholder {
    color: hsla(0, 0%, 98%, 0.7);
  }
`;

const SendButton = styled.button`
  padding: 8px 15px;
  background: linear-gradient(
    to right, 
    hsl(45, 100%, 72%), 
    hsl(35, 100%, 68%)
  );
  color: hsl(240, 2%, 12%);
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: 500;
  
  &:hover:not(:disabled) {
    opacity: 0.9;
  }
  
  &:disabled {
    background: hsl(0, 0%, 22%);
    color: hsla(0, 0%, 98%, 0.7);
  }
`;

const ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [position, setPosition] = useState(20);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startPosition, setStartPosition] = useState(0);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const handleMouseDown = (e) => {
    if (isOpen) return;
    setIsDragging(true);
    setStartX(e.clientX);
    setStartPosition(position);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const diff = startX - e.clientX;
    const newPosition = Math.max(20, Math.min(window.innerWidth - 150, startPosition + diff));
    setPosition(newPosition);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, startX, startPosition]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, isLoading]);

  const sendMessage = async (message) => {
    if (!message.trim() || isLoading) return;
    
    try {
      setIsLoading(true);
      
      const userMessage = {
        role: 'user',
        content: message.trim(),
        isTyping: false
      };
      
      const tempTypingMessage = {
        role: 'assistant',
        content: '',
        isTyping: true
      };

      setMessages(prev => [...prev, userMessage, tempTypingMessage]);
      setInput('');
      
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);

      const response = await sendMessageToOpenAI([...messages, userMessage]);
      
      setMessages(prev => [
        ...prev.slice(0, -1),
        {
          role: 'assistant',
          content: response,
          isTyping: true
        }
      ]);

      setTimeout(() => {
        setMessages(prev => prev.map(msg => 
          msg.role === 'assistant' ? { ...msg, isTyping: false } : msg
        ));
        setIsLoading(false);
        setTimeout(() => {
          inputRef.current?.focus();
        }, 0);
      }, 500);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [...prev.slice(0, -1), {
        role: 'assistant',
        content: 'Sorry, there was an error processing your message.',
        isTyping: false
      }]);
      setIsLoading(false);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage(input);
    }
  };

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  return (
    <WidgetContainer position={position}>
      {!isOpen ? (
        <ChatButton 
          onClick={() => setIsOpen(true)}
          onMouseDown={handleMouseDown}
          style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
        >
          Chat with me
        </ChatButton>
      ) : (
        <ChatWindow isFullScreen={isFullScreen}>
          <Header>
            <HeaderTitle>Chat Support</HeaderTitle>
            <HeaderControls>
              <ControlButton onClick={() => setIsFullScreen(!isFullScreen)}>
                {isFullScreen ? '⊙' : '⤢'}
              </ControlButton>
              <ControlButton onClick={() => {
                setIsFullScreen(false);
                setIsOpen(false);
              }}>
                ×
              </ControlButton>
            </HeaderControls>
          </Header>
          <MessagesContainer>
            {messages.map((message, index) => (
              <MessageBubble 
                key={index} 
                isUser={message.role === 'user'}
              >
                {message.isTyping ? (
                  message.content ? (
                    <TypeWriter text={message.content} />
                  ) : (
                    <TypingIndicator />
                  )
                ) : (
                  <ReactMarkdown 
                    className="markdown-content"
                    components={{
                      a: ({node, ...props}) => <a target="_blank" rel="noopener noreferrer" {...props} />
                    }}
                  >
                    {message.content}
                  </ReactMarkdown>
                )}
              </MessageBubble>
            ))}
            <div ref={messagesEndRef} />
          </MessagesContainer>
          <InputContainer>
            <Input 
              ref={inputRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type a message..."
              disabled={isLoading}
            />
            <SendButton 
              onClick={() => sendMessage(input)}
              disabled={isLoading || !input.trim()}
            >
              Send
            </SendButton>
          </InputContainer>
        </ChatWindow>
      )}
    </WidgetContainer>
  );
};

export default ChatWidget; 