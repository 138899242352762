import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';

// Create initialization function
const initChatWidget = () => {
  // Safety check for existing widget
  const existingWidget = document.getElementById('chat-widget-root');
  if (existingWidget) {
    existingWidget.remove();
  }

  // Create new container
  const chatContainer = document.createElement('div');
  chatContainer.id = 'chat-widget-root';
  document.body.appendChild(chatContainer);
  
  try {
    const root = createRoot(chatContainer);
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>
    );
  } catch (error) {
    console.error('Failed to initialize chat widget:', error);
  }
};

// Expose to window immediately
window.initChatWidget = initChatWidget;

// For development mode, initialize immediately if we're on the demo page
if (process.env.NODE_ENV === 'development' && document.getElementById('root')) {
  const root = createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

export { initChatWidget };
