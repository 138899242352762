import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const TypeWriterContainer = styled.div`
  white-space: pre-wrap;
  word-break: break-word;
`;

function TypeWriter({ text, onComplete }) {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText(prev => prev + text[currentIndex]);
        setCurrentIndex(prev => prev + 1);
      }, 30);

      return () => clearTimeout(timer);
    } else if (onComplete) {
      onComplete();
    }
  }, [currentIndex, text, onComplete]);

  return <TypeWriterContainer>{displayedText}</TypeWriterContainer>;
}

export default TypeWriter; 