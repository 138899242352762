export async function sendMessageToOpenAI(messages) {
  try {
    const response = await fetch('http://localhost:3003/api/chat', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ messages })
    });

    const data = await response.json();
    
    if (!response.ok) {
      if (data.error?.code === 'insufficient_quota') {
        throw new Error('API quota exceeded. Please check your billing settings.');
      }
      throw new Error(data.error?.message || 'Failed to get response from server');
    }

    return data.content;
  } catch (error) {
    console.error('Error calling chat API:', error);
    throw error;
  }
} 
