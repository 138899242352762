import React from 'react';
import styled from 'styled-components';

const IndicatorContainer = styled.div`
  display: flex;
  gap: 5px;
  padding: 10px;
`;

const Dot = styled.span`
  width: 8px;
  height: 8px;
  background-color: #8e8ea0;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;

  &:nth-child(1) {
    animation-delay: -0.32s;
  }

  &:nth-child(2) {
    animation-delay: -0.16s;
  }

  @keyframes bounce {
    0%, 80%, 100% { 
      transform: scale(0);
    }
    40% { 
      transform: scale(1);
    }
  }
`;

function TypingIndicator() {
  return (
    <IndicatorContainer>
      <Dot />
      <Dot />
      <Dot />
    </IndicatorContainer>
  );
}

export default TypingIndicator; 